
import checkForErrors from '@/helpers/checkForErrors';
import conversationAPI from '@/api/conversations';
import hasCustomFields from '@/mixins/hasCustomFields';
import { mapGetters, mapState } from 'vuex';
import NewAccountSelector from '../NewAccountSelector.vue';

export default {
  name: 'new-conversation',
  components: { NewAccountSelector },
  mixins: [hasCustomFields],

  props: {
    depth: {
      type: String,
      required: true,
    },
    depth_uuid: {
      type: String | null,
      required: true,
    },
    participants: {
      type: Array | null,
      required: false,
    },
  },

  data() {
    return {
      modelType: 'conversation',
      newConvo: {
        name: '',
        participants: [],
        contents: '',
      },
      accountParticipants: [],
      client: {},
    };
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['currentAccountUuid', 'isEmployee']),

    accountSelectorType() {
      if (this.depth === 'employee') {
        return 'employee';
      }
      if (this.depth === 'external') {
        return 'external';
      } else {
        return 'client';
      }
    },
  },

  created() {
    this.newConvo = this.buildDataBlob(this.newConvo);
  },

  mounted() {
    // if we're already at client / case level then we want to merge incoming participants with the current depth
    if (this.isEmployee) {
      let people = [];
      if (this.participants && this.participants.length) {
        people = this.participants;
      }

      this.accountParticipants = people;
    } else {
      this.accountParticipants = null;
    }
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    async createConversation() {
      if (this.depth === 'case' && this.depth_uuid) {
        this.newConvo = { ...this.newConvo, case: this.depth_uuid };
      }

      if (this.newConvo.participants.length === 0) {
        delete this.newConvo.participants;
      }
      try {
        const rsp = await conversationAPI.createConversation(this.newConvo);
        this.$nuxt.$emit('refreshConversations');
        this.newConvo = {
          name: '',
          participants: this.participants,
          contents: '',
        };
        this.$nuxt.$emit('viewConvo', rsp.data.data);
      } catch (err) {
        if (err.response.status === 403) {
          this.$nuxt.$emit('notification', err.response.data.message, 'warning', true);
        }
        checkForErrors.process(err, this.$refs.formErrorPanel);
      }
    },
  },

  watch: {
    accountParticipants(new_val) {
      if (new_val !== null) {
        this.newConvo.participants = new_val.map((v) => v.uuid);
      }
    },
  },
};
