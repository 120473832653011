
import circlesAPI from '~/api/circles';

export default {
  name: 'CircleSelector',

  props: {
    value: {
      type: Array,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Select circle',
    },
    labelSize: {
      type: String,
      required: false,
      default: '',
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      circles: [],
      loading: true,
      v: '',
    };
  },
  created() {
    this.grabData();
  },
  methods: {
    grabData() {
      circlesAPI.allPaged().then((rsp) => {
        this.circles = rsp;
        this.loading = false;

        if (this.value) {
          this.v = this.circles.filter((item) => {
            return this.value.includes(item.value);
          });
        }
      });
    },
  },
  watch: {
    v: {
      deep: true,
      handler(new_val) {
        if (new_val === null) {
          this.$emit('input', null);
        } else {
          this.$emit('input', new_val);
        }
      },
    },
  },
};
