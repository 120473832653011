import paginator from '@/helpers/paginator';

export default {
  allPaged: async (params) => await paginator.grabAllPages(`${$nuxt.$api.host}/circles`, {}, params),

  all: (params) => $nuxt.$axios.get($nuxt.$api.host + '/circles', params),
  create: (params) => $nuxt.$axios.post($nuxt.$api.host + '/circles', params),
  get: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/circles/' + uuid),
  update: (uuid, params) => $nuxt.$axios.put($nuxt.$api.host + '/circles/' + uuid, params),
  remove: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/circles/' + uuid),

  getAllEmails: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/circles/' + uuid + '/emails'),
  getDocuments: (circle_uuid, data) =>
    $nuxt.$axios.get($nuxt.$api.host + '/circles/' + circle_uuid + '/documents', data),
  getNotes: (circle_uuid, data) => $nuxt.$axios.get($nuxt.$api.host + '/circles/' + circle_uuid + '/notes', data),
  submissions: (circle_uuid, data) =>
    $nuxt.$axios.get($nuxt.$api.host + '/circles/' + circle_uuid + '/form-submissions', data),
  linkedClients: (circle_uuid, data) =>
    $nuxt.$axios.get($nuxt.$api.host + '/circles/' + circle_uuid + '/linked-clients', data),

  charges: (circle_uuid, data) => $nuxt.$axios.get($nuxt.$api.host + '/circles/' + circle_uuid + '/charges', data),
  expectations: (circle_uuid, data) =>
    $nuxt.$axios.get($nuxt.$api.host + '/circles/' + circle_uuid + '/expectations', data),
  wealth: (circle_uuid, data) => $nuxt.$axios.get($nuxt.$api.host + '/circles/' + circle_uuid + '/wealth', data),
};
