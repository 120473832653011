
import circlesAPI from '~/api/circles';
import accountAPI from '~/api/account';

export default {
  name: 'NewAccountSelector',
  props: {
    types: {
      type: String,
      default: 'client',
    },
    label: {
      type: String,
      default: 'Select contacts:',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    allowCircles: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    roles: {
      type: Array,
      required: false,
      default: () => [],
    },
    client: {
      type: Boolean,
      required: false,
      default: false,
    },
    employee: {
      type: Boolean,
      required: false,
      default: false,
    },
    external: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      isSearching: true,
      adding: null,
      nowAdding: null,
      nowAddingCircle: [],
      fullAccounts: [],
    };
  },

  computed: {
    accountList() {
      return this.fullAccounts.map((item) => item.uuid);
    },

    canAddMore() {
      if (this.multiple) {
        return true;
      } else {
        return this.fullAccounts.length === 0;
      }
    },
  },

  methods: {
    confirmNow() {
      if (this.adding === 'client') {
        if (this.multiple === false) {
          this.nowAdding = [this.nowAdding];
        }
        this.nowAdding.forEach((item) => {
          if (!this.accountList.includes(item.uuid)) {
            this.fullAccounts.push(item);
          }
        });
      }
      if (this.adding === 'circle') {
        let circles = this.nowAddingCircle.map((item) => item.uuid);
        circlesAPI
          .all({
            params: {
              include: 'accounts',
              'filter[uuid]': circles,
            },
          })
          .then((rsp) => {
            rsp.data.data.forEach((item) => {
              item.accounts.forEach((account) => {
                if (!this.accountList.includes(account.uuid)) {
                  this.fullAccounts.push(account);
                }
              });
            });
          });
      }
      this.resetState();
    },
    resetState() {
      this.adding = null;
      this.isSearching = false;
      this.nowAdding = null;
      this.nowAddingCircle = null;
    },
    removeMe(account) {
      this.fullAccounts = this.fullAccounts.filter((item) => item.uuid !== account.uuid);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          let uuids = newVal.map((item) => item.uuid);
          let missing = [];
          if (this.fullAccounts.length === 0) {
            missing = uuids;
          } else {
            missing = this.accountList.filter((item) => {
              return !uuids.includes(item);
            });
          }
          if (missing.length > 0) {
            accountAPI.getAccounts({ params: { 'filter[uuid]': missing.join(',') } }).then((rsp) => {
              this.fullAccounts = rsp.data.data;
            });
          }
        }
      },
    },

    fullAccounts: {
      deep: true,
      handler(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
};
